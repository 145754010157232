.alert{
    height: 50%;
    margin-bottom: 0;
    padding-bottom: 0;
}
.pag401{
  position: relative;
  text-align: center;
}
.pag401 h4{
    padding-top: 0 !important;
}