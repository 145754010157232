//Modificaciones
// html {
//     font-size: 0.6rem;
//   }
  
  
//   @include media-breakpoint-up(sm) {
//     html {
//       font-size: 0.6rem;
//     }
//   }
  
//   @include media-breakpoint-up(md) {
//     html {
//       font-size: 0.8rem;
//     }
//   }
  
//   @include media-breakpoint-up(lg) {
//     html {
//       font-size: 1rem;
//     }
//   }
.app-body {
    height: 85vh!important;
}
body::-webkit-scrollbar {
    width: 10px;     /* Tamaño del scroll en vertical */
  }
  
  body::-webkit-scrollbar-thumb {
    background: rgba(204, 204, 204, 0.911);
    border-radius: 6px;
  }
  
  body::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    width: 25px;     /* Tamaño del scroll en vertical */
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  body::-webkit-scrollbar-thumb:active {
    background-color: grey;
  }

  
.inputConsumo {
    max-width: 8rem;
}

.costosbox {
    // background-color: #151515de;
    background-color: #1b4214db;
    color: white;
}

.costosbackground{
    background: url(https://cdn-images-1.medium.com/max/1200/1*K7733icXPcjwga-pUhO83w.png);
    background-size: contain;
}

.titulopm{
    padding: 0.3rem 1.25rem;
    margin-bottom: 0;
    color: inherit;
    background-color: rgba(38, 78, 124, 0.14);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.graficas{
    height: 300px;

}

.margin-auto{
    margin: auto;
}
.tipoSensor{
    font-size: 0.8rem;
}
.sidebar {

    display: flex;
    flex-direction: column;
    padding: 0;
    color: #fff;
    background: #264e7c;
}

.sidebar .nav-link.active {
    color: #fff;
    background: #3165a0;
}

.react-search-field-button{
    position: relative;
    right: 28px;
}

body {
    background-color: #ffffff;
    color: #264e7c !important;
    font-size: 0.875rem !important;
    font-family: "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}


.gm-style-iw {
    text-align: center;
}
// Here you can add other styles


.loginPage {

    background-image: url(assets/img/fondologin.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    font-family: 'Numans', sans-serif;
}

.loginBox {
    // height: 370px;
    margin-top: auto;
    margin-bottom: auto;
    // width: 400px;
    background-color: rgba(0,0,0,0.5) !important;
    color: white;
    max-width: 450px;
}

.text-muted2 {
    color: #c5c5c5 !important;
}


.container-fluid{
    position: relative;
    padding: 0 !important;
    width: 100%;
    height: 100%;
}
.container-fluid > div{
    position: relative;
    width: 100%;
    height: 100%;
}
.map-manager{
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 5;
}
.map-container{
    position: relative;
    height: 100%;
    //max-height: 1000px;
    width: 100%;
    overflow: hidden;
}
.map-container > div{
    position: relative;
    height: 100%;
    max-height: 1000px;
    width: 100%;
}
.sensor-view{
    position: absolute !important;
    width: 25% !important;
    overflow-y: scroll;
    //height: 500px !important;
    height: 100% !important;
    left: 75%;
    background-color: #264e7c;
    top: 0% !important;
    //z-index: -1;
}
.sensor-side-view{
    // background-color: #264e7c;
    width: 95%;
    text-align: center;
    margin-bottom: 10px !important;
    margin: auto;
    // border-radius: 2rem;
   
}
.nombreSensor{
    font-weight: bold;
    font-size: 0.9rem;
}
.speedometer{
    height: 200px;
    cursor: pointer;
    
}
.nombre-sucursal{
    font-weight: bolder;
    height: 35% !important;
    bottom: 3% !important;
    width: 80% !important;
    visibility: hidden;

}
.map-container > div{
    position: relative;
    height: 100%;
    max-height: 100%;
    width: 100%;
}
.reloj{
    width: 20%;
    display: inline-block;
    // float: left;
}
.vista-sucursal{
    overflow: hidden;
    padding-top: 0.75rem;
}
.vista-sucursal .historics-view{
    height: 200px !important;
    width: 70% !important;
    left: 27%
}
.vista-sucursal .sensor-div{
    position: relative;

}
.vista-sucursal .reloj{
    display: block;
    float: none;
}
.vista-sucursal .sensor-side-view{
    width: 98%;
}
.listado-sucursales{
    position: relative;
    left: 0;
    width: 20%;
    height: 100%;
    background-color: red;
}
.edicion-sucursal{
    position: relative;
    left: 20%;
    top: -100%;
    width: 80%;
    height: 100%;
    background-color: blue;

}
.sensor-div{
    // margin-top: 0.6rem;
    // margin-bottom: 0.6rem;
    
}
.valorSensor{
    font-size: 1rem;
    font-weight: 400;
}
.logo_service{
    margin: auto;
    height: 49px;
}
// .regla div{
//     display: inline-block;
//     border: solid black;
//     text-align: center;
// }
.nombreRegla{
    width: 30%;
}
.accionRegla{
    width: 20%
}
.operadorRegla{
    width: 10%;
}
.valorRegla{
    width: 10%
}
.attributeRegla{
    width: 20%
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
}
th {
    background-color: #bfc1c3;
}
td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    text-align: center;
}

tr:nth-child(odd) {
    background-color: #dddddd;
}
form {
    position: relative;
}
form div{
    display: inline-block;
    position: absolute;
}
.tabla{
    display: table;
    text-align: center;
}
.fila {
    display: table-row;
}
.celda{
    display: table-cell;
}



.desarrollado {
    display: block;
    font-size: 0.9rem;
    
}
.navbar{
    padding: 0 !important;
}

@media only screen and (max-width: 500px) {
    

    // .sensor-div{
    //     padding-bottom: 0.3rem;
    // }

 


    .desarrollado {
        display: none;
    }

   

    .navbar {
        padding: 3px !important;
    }
    
  .img-avatar{
      height: 30px !important;
      margin: 0px !important;
  }
  a .navbar-brand-full{
      width: 75%;
  }

}

// @media (max-width: 991.98px){
// .app-body{
//     margin-top: 0px;
//     }
// }